import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import NavBar from '../Navbar/navbar';
import './food.css'
import '../getpackage/getPackage.css'
const GetFood = () => {
    const navigate = useNavigate()
    const token = localStorage.getItem('admintoken')
    const [item, setItems] = useState([]);
    useEffect(() => {
        getFood();
    }, []);
    async function getFood() {
        await axios({
            'method': 'GET',
            'url': `https://node.firehrm.com/FB/flame/restaurant/getFoodByOwner`,
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then((data) => {
            console.log(data)
            // const data = await res.json();
            let result = data.data.message;
            console.log(result);
            setItems(result)
        })
    };
    function nav(e, x) {
        navigate('/addFood', { state: x })
    }
    function navView(e, x) {
        navigate('/foodlist', { state: x })
    }
    // async function Delete(e, id) {
    //     e.preventDefault()
    //     console.log(id);
    //     if (window.confirm("Are you sure to Delete")) {
    //         await fetch('https://node.firehrm.com/FB/flame/restaurant/deleteFood/' + id, {
    //             method: 'DELETE',
    //             header: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json',
    //             }

    //         })
    //             .then(response => {
    //                 if (response.status == 200 || response.status == 204) {
    //                     toast.success('Deleted Successfull', {
    //                         position: "bottom-center",
    //                         autoClose: 5000,
    //                         hideProgressBar: false,
    //                         closeOnClick: true,
    //                         pauseOnHover: true,
    //                         draggable: true,
    //                         progress: undefined
    //                     });
    //                     getAllAdminLimit()
    //                 }
    //             })
    //             .then(data => {

    //                 console.log(data)
    //             }).catch(error => {
    //                 toast.error('Some Problem Occur. Try Again or later', {
    //                     position: "bottom-center",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined
    //                 });
    //             })
    //     }


    // }
    
 
    return (
        <>
            <NavBar />
            <div className="content-wrapper">
                <div className="container">
                <div className="row mx-3 mt-2">
                        <div className="col-2 mb-5">
                            <button type="button" className=' ms-3 text-light btnaddnew btn fw-bold' onClick={(e) => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i></button>

                        </div>
                        <div className="col-lg-8">
                            <h5 className="text-center mt-1 mb-5 loginAcc">Food Details</h5>
                        </div></div>
                    {item?.length > 0 && item.map((x) =>
                        <>
                            <div className="card">
                                <div className="row mx-2 mt-2">
                                    <div className="col-lg-8 col-md-6 col-sm-12 ">
                                        <p className='headRes'>{x.restaurantName}</p>
                                        <p className='bodyRes'>{x.restaurantAddress} , {x.address}</p>
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-sm-12 text-right mt-4">

                                    <button type="button" className='btn me-1 btn-success buttonSizeAcceptReject' onClick={(e)=>navView(e,x)}>view Food</button>
                                   
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-sm-12 text-left mt-3">

                                        <p> <button type="button" className=' ms-3 text-light btnaddnew btn fw-bold mt-2' onClick={(e) => nav(e, x)}>+</button> </p>
                                        {/* <p className='bodyRes'>{x.restaurantAddress} , {x.address}</p> */}
                                    </div>
                                    {/* <div className="col-md-12">
                                        <hr />
                                    </div> */}
                                    {x.foodList?.length > 0 ?
                                        // <div className="row">

                                        //     {x.foodList?.map((y) => <>
                                        //         <div className="col-2">
                                        //             <img
                                        //                 src={y.foodImage}
                                        //                 className="image1 img-fluid"
                                        //                 alt="Thumb"
                                        //             />
                                        //         </div>
                                        //         <div className="col-2">
                                        //             <p className='bodyRes fw-bold'>{y.foodName}</p>
                                        //         </div>
                                        //         <div className="col-2">
                                        //             <p className='bodyRes '>{y.category}</p>

                                        //         </div>
                                        //         <div className="col-2">
                                        //             <p className='bodyRes '>{y.cuisine}</p>
                                        //         </div>
                                        //         <div className="col-1">

                                        //             {Object.keys(y.option[0])?.length > 0 &&
                                        //                 y.option[0].addSize?.length > 0 &&
                                        //                 y.option[0].addSize?.map((z) =>
                                        //                     <p className='bodyRes '>{z.size} - {z.sizePrice}</p>
                                        //                 )

                                        //             }
                                        //         </div>
                                        //         <div className="col-1">

                                        //             {Object.keys(y.option[0])?.length > 0 &&
                                        //                 y.option[0].addIngredient?.length > 0 &&
                                        //                 y.option[0].addIngredient?.map((z) =>
                                        //                     <p className='bodyRes '>{z.integredient} - {z.ingredientPrice}</p>
                                        //                 )

                                        //             }
                                        //         </div>
                                        //         <div className="col-2">
                                                    
                                        //         </div>
                                        //     </>
                                        //     )

                                        //     }</div>
                                        ""
                                        : <p>Add Some Food</p>}

                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

        </>
    );
}


export default GetFood;