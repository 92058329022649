import React,{useEffect,useState} from 'react';
import axios from 'axios';
import './getpackageforRes.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
const GetpackageforRes = ({ResAdminData}) => {    
    const [item, setItems] = useState([]);
    console.log(ResAdminData.free,"res")
    async function allpackage() {
    const data = await axios({
        'method': 'GET',
        'url':  `https://node.firehrm.com/FB/flame/superAdmin/getPackagePlan`,
        'headers': {
            'Content-Type': 'application/json',
        },
    })
    console.log(data)


    let result = await data.data.message;
    if(ResAdminData.free=="true"){
        let data= result.filter((x)=>x.packagePlan!="Free")
        setItems(data)
        console.log(ResAdminData.free,item,"this")
    }
   else
   {
    setItems(result);
    console.log(ResAdminData.free,item,"else")
   }
  

};
useEffect(() => {
    allpackage();
    console.log(ResAdminData)
}, [ResAdminData.free]);
async function submitPackage(e,list){
   let subscriptionPlan=list.packagePlan;
   let addAccess=list.addAccess
    let payload={subscriptionPlan,addAccess}
    await fetch('https://node.firehrm.com/FB/flame/admin/packagePlan',
    {
        method: 'POST',
        headers: {
            'Content-type': 'application/JSON',
            'Authorization':localStorage.getItem('admintoken')
        },
        body: JSON.stringify(payload)
    }).then(res=>{
        console.log(res)
        if(res.status==200 || res.status==204 ||res.status==201)
        {
            window.location.reload(false)
            toast.success('Package Added', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined});
           
        }
        else{
            toast.warn('Something Went Wrong', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined});
            
            }
    })
}
    return (
    <>
      <ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover/>
    <div className="content-wrapper">
    	<div className="container mx-2">			
			<div className="main_title center">
				<span><em></em></span>
				<h2>Our Pricing Plans</h2>
				<p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
			</div>
			<div className="row">
				{item.map((x)=><>
               
                    <div className="col-lg-4 col-md-6 mt-2">
                     <div className="card">
				 <div className="plan-title text-center">
					 <h3 className='mt-2'>{x.packageName}</h3>
                     <hr/>
                     <div className="containWidth">
                         <div className=''>{x.description}</div>
                         </div>
					<hr/>
				 </div>
				 <p className="plan-price ms-2">Amount -<strong>{x.amount==0?" Free":" Rs."+x.amount}</strong></p>
				 <ul className="plan-features ">
	
					 <li><strong>Check and go</strong> included</li>
                         <li><strong>APP</strong> included</li>
					 <li><strong>{x.packagePlan}</strong> valid</li>
                     <li><strong>You can Add and Access{" "+x.addAccess}</strong> Restaurant</li>
					 <li><strong>Unsubscribe</strong> anytime</li>
				 </ul>
				 <input type='button' className="btn text-white btn-secondary" value="Submit" onClick={(e)=>submitPackage(e,x)}/>

                 </div>
			 </div> 
                
                    </>
              
			
				
				)}
			</div>
			
            {/* <!-- End row plans--> */}
		</div>
        </div>
    </>
    );
}


export default GetpackageforRes;