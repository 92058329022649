import React, { useState } from 'react';
import './createPackage.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { useLocation, useNavigate } from 'react-router-dom';
const CreatePackage = () => {
    const navigate=useNavigate()
    const {state}=useLocation()
    console.log(state,"state")
    const [packageName, setpackage] = useState(state!=null?state.packageName:"")
    const [amount, setAmount] = useState(state!=null?state.amount:0)
    const [packagePlan, setValidation] = useState(state!=null?state.packagePlan:"")
    const [description, setDescription] = useState(state!=null?state.description:"");
    const [addAccess, setaddAccess] = useState(state!=null?state.addAccess:0);
    async function createpackage(e) {
        e.preventDefault();
        let payload = { packageName, amount, packagePlan, description ,addAccess}
        console.log(payload)
        if(state==null){
            var url="https://node.firehrm.com/FB/flame/superAdmin/createAdminPackage"
            var method="POST"
        }
        else{
            var url="https://node.firehrm.com/FB/flame/superAdmin/updatePackage/"+state._id;
            var method="PUT"
        }
         await fetch(url,
            {
                method: method,
                headers: {
                    'Content-type': 'application/JSON'
                },
                body: JSON.stringify(payload)
            }).then(res => {
                console.log(res)
                if(res.status==200||res.status==201){
                  
                     toast.success(' Add Package Successfull ', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });	
                    navigate('/getPackage')
                }
                else if(res.status==400){

                    toast.warn('Invalid Input', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });	
                  
                }
                else if(res.status==500){

                    toast.error('Internal Error. Try again', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });	
                }
                else{

                    toast.error('Something went wrong. Try again', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });	
                }
            }).catch(err => {
                console.log(err)
                alert("Something went wrong. Try again")
                toast.error('Something went wrong. Try again', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
    }
    return (
        <>
        <ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
            <div class="content-wrapper">
                <p className='text-center fw-bold fs-5 mt-5'>Package</p>
                <form onSubmit={(e)=>createpackage(e)}>
                    <div className="form-row mx-5 ">
                        <div className="form-group col-md-6 mt-2">
                            <label For="resName">Package Name</label>
                            <input type="text" className="form-control" id="resName" placeholder="Package Name" value={packageName} onChange={(e) => setpackage(e.target.value)} required />
                        </div>
                        <div className="form-group col-md-6 mt-2">
                            <label For="resEmail">Package Amount</label>
                            <input type="tel" className="form-control" id="resEmail" placeholder="Enter Amount" value={amount} onChange={(e) => setAmount(e.target.value)} required />
                        </div>

                        <div className="form-group col-md-6 mt-2">
                            <label For="resContact">Validation</label>
                            
                        <select name="department" class="form-control widthValidate selectpicker" onChange={(e) => setValidation(e.target.value)} required>
                            <option value="Selected Class">Select Validation Month</option>
                            <option value="Free">Free for a Month</option>
                            <option value="3 months" >3 months</option>
                            <option value="6 months">6 months</option>
                            <option value="12 months">12 months</option>
                        </select>
                        
                        </div>
                        <div className="form-group col-md-6 mt-2">
                            <label For="resContact">How many restaurant they can add and access ?</label>
                            <input type="tel" className="form-control" id="resEmail" placeholder="Enter in Number" value={addAccess} onChange={(e) => setaddAccess(e.target.value)} required />
                        </div>
                        <div className="form-group col-md-12 mt-2">
                            <label For="resContact">Package Description</label>
                            <textarea name="" id="" cols="10" rows="5" className="form-control" placeholder="Package Description" value={description} onChange={(e) => setDescription(e.target.value)} required></textarea>
                        </div>
                       
                        <div className="form-group col-md-12 mt-2">
                            <div className="text-center">
                                <input type="submit" value="Submit" className='submit btn'/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default CreatePackage;