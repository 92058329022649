import logo from './logo.svg';
import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './component/Home/home';
import NavBar from './component/Navbar/navbar';
import Footer from './component/Footer/footer';
import Helmet from 'react-helmet';
import AddRes from './component/Restaurant/addRes';
import UpdateRes from './component/Restaurant/updateRes';
import RestaurantList from './component/Restaurant/getRes';
import Restauranthome from './component/Restaurant/restauranthome';
import Login from './component/LoginAdmin/loginSuper';
import { AuthChild } from "./component/auth";
import $ from "jquery"
import LoginSuper from './component/LoginAdmin/loginSuper';
import LoginAdmin from './component/LoginAdmin/loginAdmin';
import CreatePackage from './component/createPackage/createPackage';
import GetPackage from './component/getpackage/getPackage';
import GetAdminRequest from './component/getAdminResquest/getAdminRequest';
import GetpackageforRes from './component/getpackageforRes/getpackageforRes';
import Getviewrequest from './component/getAdminResquest/getviewrequest';
import ViewSingleRes from './component/Restaurant/viewSingleRes';
import AddFood from './component/addFood/addFood';
import GetFood from './component/addFood/getFood';
import Foodlist from './component/addFood/foodlist';
import UpdateFood from './component/addFood/updateFood.jsx';
import AddContent from './component/content/add-content';
import GetContent from './component/content/get-content';
import AddFaq from './component/Faq/add-faq';
import GetFaq from './component/Faq/get-faq';
import GetDelivery from './component/Delivery/get-delivery';
import GetOrderList from './component/GetOrderList/getOrderList';
import AdminList from './component/AdminList/AdminList';
import RestarauntList from './component/RestatauntList/RestarauntList';
import UserList from './component/UserList/UserList';
import AdminHome from './component/Home/AdminHome';
import SuperAdminHome from './component/Home/SuperAdminHome';
function App() {
  const token = localStorage.getItem('admintoken')
  const [ResAdminData, setdata] = useState({})
  AuthChild.isAuthenticated()
  return (
    <>
      {AuthChild.isLogged ?
        <>
          {AuthChild.isRole ?
            <Router>
              <NavBar setdata={setdata} />
              <Routes>
                <Route
                  exact path="/"
                  element={<AdminHome />}
                />
                <Route
                  path="/addRes"
                  element={<AddRes ResAdminData={ResAdminData} />}
                />
                <Route
                  path="/getRes"
                  element={<RestaurantList ResAdminData={ResAdminData} />}
                />
                <Route
                  path="/updateRes"
                  element={<UpdateRes ResAdminData={ResAdminData} />}
                />
                <Route
                  path="/addRes"
                  element={<UpdateRes />}
                />
                <Route
                  path="/addFood"
                  element={<AddFood />}
                />
                <Route
                  path="/updateFood"
                  element={<UpdateFood />}
                />
                <Route
                  path="/getFood"
                  element={<GetFood />}
                />
                <Route
                  path="/foodlist"
                  element={<Foodlist />}
                />
                <Route
                  path="/getpackageforRes"
                  element={<GetpackageforRes ResAdminData={ResAdminData} />}
                />
                <Route
                  path="/restauranthome"
                  element={<Restauranthome />}
                />
                <Route
                  path="/viewSingleRes"
                  element={<ViewSingleRes />}
                />
                <Route
                  path="/getorderlist"
                  element={<GetOrderList />}
                />
              </Routes>
              <Footer />
            </Router>
            :
            <Router>
              <NavBar />
              <Routes>
                <Route
                  exact path="/"
                  element={<SuperAdminHome />}
                />
                <Route
                  exact path="/createPackage"
                  element={<CreatePackage />}
                />
                <Route
                  exact path="/getPackage"
                  element={<GetPackage />}
                />
                <Route
                  exact path="/getAdminRequest"
                  element={<GetAdminRequest />}
                />
                <Route
                  exact path="/getviewrequest"
                  element={<Getviewrequest />}
                />
                <Route
                  exact path="/addcontent"
                  element={<AddContent />}
                />
                <Route
                  exact path="/addfaq"
                  element={<AddFaq />}
                />
                <Route
                  exact path="/getfaq"
                  element={<GetFaq />}
                />

                <Route
                  exact path="/getdelivery"
                  element={<GetDelivery />}
                />
                <Route
                  exact path="/adminlist"
                  element={<AdminList />}
                />
                <Route
                  exact path="/restarauntlist"
                  element={<RestarauntList />}
                />
                  <Route
                  exact path="/userlist"
                  element={<UserList />}
                />
              </Routes>
            </Router>
          }
        </>
        :
        <Router>
          <Routes>
            <Route
              exact path="/"
              element={<LoginSuper />}
            />

            <Route
              exact path="/loginAdmin"
              element={<LoginAdmin />}
            />
          </Routes>
        </Router>

      }</>

  );

}

export default App;
