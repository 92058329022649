import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import logo from '../../assets/img/Flame Bee8.png'
import { AuthChild } from '../auth';
import axios from 'axios';
const NavBar = ({ setdata }) => {
    const [user, setUser] = useState({});
    const navigate = useNavigate();
    AuthChild.isAuthenticated()
    function logout(e) {
        e.preventDefault();
        localStorage.removeItem('admintoken');
        localStorage.removeItem('superadmintoken')
        navigate('/')
        window.location.reload(false)
    }
    async function userdetail(token) {
        const data = await axios({
            'method': 'GET',
            'url': `https://node.firehrm.com/FB/flame/admin/getSingleAdminPackage`,
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then(res => {
            console.log(res.data.message, "getSingleAdminPackage")
            // setdata(res.data.message)
            setUser(res.data.message)
        })
    };
    useEffect(() => {
        let token = localStorage.getItem('admintoken')
        if (token != undefined || token != null) {
            userdetail(token);
        } else {
            return;
        }
    }, []);
    return (
        <>
            <nav class="navbar navbar-expand-lg navbar-dark bg-default fixed-top" id="mainNav">
                <a class="navbar-brand" href="/"><img src={logo} alt="" width="36" height="36" /></a>
                <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav navbar-sidenav" id="exampleAccordion">
                        {Object.keys(user).length != 0 ?
                            <>
                                {/* <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Reviews">
                                    <a class="nav-link" href="/getpackageforRes">
                                        <i class="fa fa-archive" aria-hidden="true"></i>
                                        <span class="nav-link-text ms-2">Package Plan</span>
                                    </a>
                                </li> */}
                                <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Reviews">
                                    <a class="nav-link" href="/addres">
                                        <i class="fa fa-archive" aria-hidden="true"></i>
                                        <span class="nav-link-text ms-2">Add Restaraunt</span>
                                    </a>
                                </li>

                                <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Reviews">
                                    <a class="nav-link" href="/getRes">
                                        <i class="fa fa-archive" aria-hidden="true"></i>
                                        <span class="nav-link-text ms-2">Restaurant List</span>
                                    </a>
                                </li>
                                {/* <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Reviews">
                                    <a class="nav-link" href="/addFood">
                                        <i class="fa fa-archive" aria-hidden="true"></i>
                                        <span class="nav-link-text ms-2">Add Food</span>
                                    </a>
                                </li> */}

                                <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Reviews">
                                    <a class="nav-link" href="/foodlist">
                                        <i class="fa fa-archive" aria-hidden="true"></i>
                                        <span class="nav-link-text ms-2">Food List</span>
                                    </a>
                                </li>
                                <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Reviews">
                                    <a class="nav-link" href="/getorderlist">
                                        <i class="fa fa-archive" aria-hidden="true"></i>
                                        <span class="nav-link-text ms-2">Order List</span>
                                    </a>
                                </li>
                            </> :
                            <>
                                {/* <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Reviews">
                                    <Link to='/createPackage'>
                                        <a class="nav-link" >
                                            <i class="fa fa-fw fa-star"></i>
                                            <span class="nav-link-text ms-2">Create Package</span>
                                        </a>
                                    </Link>
                                </li>
                                <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Reviews">
                                    <Link to='/getPackage'>

                                        <a class="nav-link" href="reviews.html">
                                            <i class="fa fa-fw fa-star"></i>
                                            <span class="nav-link-text ms-2">Get Package</span>
                                        </a>
                                    </Link>
                                </li> */}
                                <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Reviews">
                                    <Link to='/getAdminRequest'>
                                        <a class="nav-link" >
                                            <i class="fa fa-fw fa-star"></i>
                                            <span class="nav-link-text ms-2">Get Admin Request</span>
                                        </a>
                                    </Link>
                                </li>
                                {/* <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Reviews">
                                    <Link to='/addcontent'>
                                        <a class="nav-link" >
                                            <i class="fa fa-fw fa-star"></i>
                                            <span class="nav-link-text ms-2">Create Content</span>
                                        </a>
                                    </Link>
                                </li>
                                <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Reviews">
                                    <Link to='/getcontent'>
                                        <a class="nav-link" >
                                            <i class="fa fa-fw fa-star"></i>
                                            <span class="nav-link-text ms-2">Get Content</span>
                                        </a>
                                    </Link>
                                </li> */}
                                <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Reviews">
                                    <Link to='/addfaq'>
                                        <a class="nav-link" >
                                            <i class="fa fa-fw fa-star"></i>
                                            <span class="nav-link-text ms-2">Add Faq</span>
                                        </a>
                                    </Link>
                                </li>
                                <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Reviews">
                                    <Link to='/getfaq'>
                                        <a class="nav-link" >
                                            <i class="fa fa-fw fa-star"></i>
                                            <span class="nav-link-text ms-2">Get Faq</span>
                                        </a>
                                    </Link>
                                </li>

                                <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Reviews">
                                    <Link to='/getdelivery'>
                                        <a class="nav-link" >
                                            <i class="fa fa-fw fa-star"></i>
                                            <span class="nav-link-text ms-2">Get order List</span>
                                        </a>
                                    </Link>
                                </li>
                                <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Reviews">
                                    <Link to='/adminlist'>
                                        <a class="nav-link" >
                                            <i class="fa fa-fw fa-star"></i>
                                            <span class="nav-link-text ms-2">Get Admin List</span>
                                        </a>
                                    </Link>
                                </li>
                                <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Reviews">
                                    <Link to='/userlist'>
                                        <a class="nav-link" >
                                            <i class="fa fa-fw fa-star"></i>
                                            <span class="nav-link-text ms-2">Get User List</span>
                                        </a>
                                    </Link>
                                </li>
                                <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Reviews">
                                    <Link to='/restarauntlist'>
                                        <a class="nav-link" >
                                            <i class="fa fa-fw fa-star"></i>
                                            <span class="nav-link-text ms-2">Get Restaraunt List</span>
                                        </a>
                                    </Link>
                                </li>
                            </>
                        }
                    </ul>
                    <ul class="navbar-nav sidenav-toggler">
                        <li class="nav-item">
                            <a class="nav-link text-center" id="sidenavToggler">
                                <i class="fa fa-fw fa-angle-left"></i>
                            </a>
                        </li>
                    </ul>
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle mr-lg-2" id="messagesDropdown" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-fw fa-envelope"></i>
                                <span class="d-lg-none">Messages
                                    <span class="badge badge-pill badge-primary">12 New</span>
                                </span>
                                <span class="indicator text-primary d-none d-lg-block">
                                    <i class="fa fa-fw fa-circle"></i>
                                </span>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="messagesDropdown">
                                <h6 class="dropdown-header">New Messages:</h6>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">
                                    <strong>David Miller</strong>
                                    <span class="small float-right text-muted">11:21 AM</span>
                                    <div class="dropdown-message small">Hey there! This new version of SB Admin is pretty awesome! These messages clip off when they reach the end of the box so they don't overflow over to the sides!</div>
                                </a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">
                                    <strong>Jane Smith</strong>
                                    <span class="small float-right text-muted">11:21 AM</span>
                                    <div class="dropdown-message small">I was wondering if you could meet for an appointment at 3:00 instead of 4:00. Thanks!</div>
                                </a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">
                                    <strong>John Doe</strong>
                                    <span class="small float-right text-muted">11:21 AM</span>
                                    <div class="dropdown-message small">I've sent the final files over to you for review. When you're able to sign off of them let me know and we can discuss distribution.</div>
                                </a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item small" href="#">View all messages</a>
                            </div>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle mr-lg-2" id="alertsDropdown" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-fw fa-bell"></i>
                                <span class="d-lg-none">Alerts
                                    <span class="badge badge-pill badge-warning">6 New</span>
                                </span>
                                <span class="indicator text-warning d-none d-lg-block">
                                    <i class="fa fa-fw fa-circle"></i>
                                </span>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="alertsDropdown">
                                <h6 class="dropdown-header">New Alerts:</h6>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">
                                    <span class="text-success">
                                        <strong>
                                            <i class="fa fa-long-arrow-up fa-fw"></i>Status Update</strong>
                                    </span>
                                    <span class="small float-right text-muted">11:21 AM</span>
                                    <div class="dropdown-message small">This is an automated server response message. All systems are online.</div>
                                </a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">
                                    <span class="text-danger">
                                        <strong>
                                            <i class="fa fa-long-arrow-down fa-fw"></i>Status Update</strong>
                                    </span>
                                    <span class="small float-right text-muted">11:21 AM</span>
                                    <div class="dropdown-message small">This is an automated server response message. All systems are online.</div>
                                </a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">
                                    <span class="text-success">
                                        <strong>
                                            <i class="fa fa-long-arrow-up fa-fw"></i>Status Update</strong>
                                    </span>
                                    <span class="small float-right text-muted">11:21 AM</span>
                                    <div class="dropdown-message small">This is an automated server response message. All systems are online.</div>
                                </a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item small" href="#">View all alerts</a>
                            </div>
                        </li>
                        <li class="nav-item">
                            <form class="form-inline my-2 my-lg-0 mr-lg-2">
                                <div class="input-group">
                                    <input class="form-control search-top" type="text" placeholder="Search for..." />
                                    <span class="input-group-btn">
                                        <button class="btn btn-primary" type="button">
                                            <i class="fa fa-search"></i>
                                        </button>
                                    </span>
                                </div>
                            </form>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="modal" data-target="#exampleModal" onClick={(e) => logout(e)}><i class="fa fa-fw fa-sign-out"></i>Logout</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default NavBar;