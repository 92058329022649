import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import NavBar from '../Navbar/navbar';
import '../Restaurant/restaurant.css'
import './getPackage.css'
const GetPackage = () => {
    const navigate = useNavigate();
    const [item, setItems] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    async function allpackage() {
        const data = await axios({
            'method': 'GET',
            'url': `https://node.firehrm.com/FB/flame/superAdmin/getPackagePlan`,
            'headers': {
                'Content-Type': 'application/json',
            },
        })
        console.log(data)


        let result = await data.data.message;
        setItems(result)
    };
    useEffect(() => {
        allpackage();
    }, []);
    const searchItems = async (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = item.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults(item)
        }
    }
    function navtoAdd(e) {
        e.preventDefault()
        navigate('/createPackage')
    }
    async function Delete(e, id) {
        e.preventDefault()
        console.log(id);
        if (window.confirm("Are you sure to Delete")) {
            await fetch('https://node.firehrm.com/FB/flame/superAdmin/removePackage/' + id, {
                method: 'DELETE',
                header: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }

            })
                .then(response => {
                    if (response.status == 200 || response.status == 204) {

                        allpackage()
                        window.location.reload(false)
                    }
                })
                .then(data => {

                    console.log(data)
                }).catch(error => {
                    alert(error)
                })
        }


    }
    async function Edit(e, list) {
        console.log("Edit")
        navigate('/createPackage', { state: list })
    }
    return (
        <>  <NavBar />

            <div className="content-wrapper">
                <div className="container mt-5">
                    <div className="text-center">
                        <h3 className=''>Package</h3>
                    </div>
                    <p>You can Add only Three Packages</p>
                    <div className="row">
                        <div className="col-lg-4 col-md-7 col-sm-7">
                            <div className="input-group mt-3 mb-3">
                                <input type="text" className="textfield form-control" placeholder="Search Restaurant" onChange={(e) => searchItems(e.target.value)} />
                                <button className="btn text-light btnstyle" type="button" ><i className="fa fa-search" aria-hidden="true"></i></button>
                            </div></div>
                        <div className="col-lg-6 col-md-1"></div>
                        <div className="col-lg-2 mt-3 col-md-4 col-sm-2 ms-auto">
                            {item.length <= 3 &&
                                <button type="button" className=' ms-3 btnaddnew btn btn-outline-white fw-bold' onClick={(e) => navtoAdd(e)}>+</button>
                            }

                        </div>
                    </div>

                    <table className='mt-5'>
                        {item.length == 0 ? "" :
                            <thead>
                                <tr>
                                    {/* <th scope="col">S.No</th> */}
                                    <th scope="col">Package</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Validation Months</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Access Restaurant Count</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>}

                        {searchInput.length > 0 ?
                            filteredResults.length > 0 && filteredResults.map((list =>
                                <tr>
                                    {/* <td data-label=""></td> */}
                                    <td data-label="Package">{list.packageName}</td>
                                    <td data-label="Amount">{list.amount}</td>
                                    <td data-label="Validation Month">{list.packagePlan}</td>
                                    <td data-label="Description">{list.description}</td>
                                    <td data-label="Access Restaurant Count">{list.addAccess}</td>

                                    <td data-label="Action"><button type="button" className='btn me-1' onClick={(e) => Edit(e, list)}><i class="fa fa-edit"></i></button><button type="button" className='btn ' onClick={(e) => Delete(e, list._id)}><i class="fa fa-trash" aria-hidden="true"></i></button></td>

                                </tr>
                            )) : item.length > 0 && item.map((list =>
                                <tr>
                                    {/* <td data-label="S.NO"></td> */}
                                    <td data-label="Package">{list.packageName}</td>
                                    <td data-label="Amount">{list.amount}</td>
                                    <td data-label="Validation Month">{list.packagePlan}</td>
                                    <td data-label="Description">{list.description}</td>
                                    <td data-label="Access Restaurant Count">{list.addAccess}</td>
                                    <td data-label="Action"><button type="button" className='btn me-1 ' onClick={(e) => Edit(e, list)}><i class="fa fa-edit"></i></button><button type="button" className='btn ' onClick={(e) => Delete(e, list._id)}><i class="fa fa-trash" aria-hidden="true"></i></button></td>
                                </tr>
                            ))
                        }
                    </table>
                    {item.length == 0 &&
                        <div className="text-center mt-3">
                            <p>No result Found. Add Package</p>
                        </div>
                    }

                </div></div>
        </>);
}



export default GetPackage;

