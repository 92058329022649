import React, { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import NavBar from '../Navbar/navbar';
import '../Restaurant/restaurant.css';
import '../getpackage/getPackage.css';

const AddFood = () => {
    const navigate = useNavigate()
    const ref = useRef();
    const [foodName, setFood] = useState("");
    const [foodImage, setFoodImage] = useState("");
    const [image, setImage] = useState([]);
    const [category, setcategory] = useState("");
    const [cuisine, setcuisine] = useState("");
    const [addSize, setaddSize] = useState([]);
    const [addIngredient, setAddIngredient] = useState([]);
    const [foodPrice, setprice] = useState();
    const [sizePrice, setsizePrice] = useState("");
    const [ingredientPrice, setingredientPrice] = useState("");
    const [size, setsize] = useState("");
    const [ingredient, setingredient] = useState("");
    const [restaurantId, setrestaurantId] = useState("");
    const { state } = useLocation();
    console.log(state,'state')
    // let restaurantId = state._id
    // console.log(restaurantId)

    const addFood = async (e) => {
        e.preventDefault();
        let restaurantId = state._id
        console.log(restaurantId)
        let option = [{ addSize, addIngredient }]
        let payload = { foodName, category, cuisine, option, foodPrice, foodImage }
        console.log(payload);
        await fetch('https://node.firehrm.com/FB/flame/restaurant/addFood/'+restaurantId, {
            method: 'POST',
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(payload)
        })
            .then(response => response)
            .then(data => {
                console.log(data)
                if (data.status === 200 || data.status === 204) {
                    toast.success('Food Added Successfully', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                    navigate('/foodlist')
                    setFood("");
                    setFoodImage("");
                    setImage([]);
                    setcategory("");
                    setcuisine("");
                    setaddSize([]);
                    setAddIngredient([]);
                    setprice("");
                    setsizePrice("");
                    setingredientPrice("");
                    setsize("");
                    setingredient("");

                } else if (data.status == 400) {
                    toast.warn('Invalid Input', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                }
                else {
                    toast.error('Failed to add. Try again Later', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                }
            }).catch(error => {
                toast.success('Failed to add. Try again Later', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            })
    }
    async function urlConvertor(e) {
        e.preventDefault();
        const formData1 = new FormData()
        formData1.append('image', image);
        let response = await fetch('https://node.firehrm.com/FB/flame/restaurant/image', {
            method: 'POST',
            body: formData1
        })
        response = await response.json()
        console.log(response.message.image)
        setFoodImage(response.message.image)

    }
    //  function remove(e, list, type) {
    //     e.preventDefault()
    //     switch (type) {

    //         case "addSize":
    //             let value1 = addSize.filter((x) => x != list)
    //             setaddSize(value1)
    //             break;
    //         case "addIngredient":
    //             console.log()
    //             let value2 = addIngredient.filter((x) => x != list)
    //             setAddIngredient(value2)
    //             break;
    //         default:
    //             break;
    //     }
    // }
    // function addarray(e, type) {
    //     e.preventDefault()
    //     switch (type) {
    //         case 'addSize':
    //             // console.log('addSize')
    //             // var price = sizePrice

    //             setaddSize([...addSize, { size, sizePrice }])
    //             setsize(""); setsizePrice("")
    //             break;
    //         case 'addIngredient':
    //             console.log('addIngredient')

    //             setAddIngredient([...addIngredient, { ingredient, ingredientPrice }])
    //             setingredient(""); setingredientPrice("")
    //             break;

    //         default:
    //             break;
    //     }


    // }
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <NavBar />

            <div class="content-wrapper">
                <form onSubmit={(e) => addFood(e)}>
                    <div className="row mx-3 mt-2">
                        <div className="col-2 mb-5">
                            <button type="button" className=' ms-3 text-light btnaddnew btn fw-bold' onClick={(e) => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i></button>

                        </div>
                        <div className="col-lg-8">
                            <h5 className="text-center mt-1 mb-5 loginAcc">Add New Food</h5>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12  ">

                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label For="resName">Food Name</label>
                                    <input type="text" className="form-control" id="resName" placeholder="Enter Food Name" value={foodName} onChange={(e) => setFood(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-12">


                                    <label For="res1">Food Image</label>
                                    <div className=''>
                                        {foodImage &&
                                            <img
                                                src={foodImage}
                                                className="image1 img-fluid"
                                                alt="Thumb"
                                            />}
                                    </div>

                                    <div className="row">
                                        <div className="col-8">
                                            <label For="image" className='labelImage'>Click me {image.name != undefined && image.name != null && " : " + image.name}</label>
                                            <input
                                                accept="image/*"
                                                type="file"
                                                onChange={(e) => setImage(e.target.files[0])}
                                                ref={ref}
                                                id='image'
                                                style={{ visibility: "hidden" }}
                                            // className='uploadImage'
                                            />
                                        </div>
                                        <div className="col-4">
                                            <button type="button" For="image" className='btn uploadImage' onClick={(e) => { urlConvertor(e, "foodImage") }} required>Upload</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <label For="resAddress">Price</label>
                                    <input type="text" className="form-control" placeholder="Price" value={foodPrice} onChange={(e) => setprice(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-12">
                                    <label For="resAddress">category</label>
                                    <input type="text" className="form-control" placeholder="Price" value={category} onChange={(e) => setcategory(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-12">
                                    <label For="resAddress">Cuisine</label>
                                    <input type="text" className="form-control" placeholder="Price" value={cuisine} onChange={(e) => setcuisine(e.target.value)} required />
                                </div>
                                {/* <div className="form-group col-md-12 mt-3">
                                    <label For="resName">Select Category</label>
                                    <select name="department" class="form-control widthValidate selectpicker" onChange={(e) => setcategory(e.target.value)} required>
                                        <option value="Selected Class">Select Category</option>
                                        {state.category?.length > 0 &&
                                            state.category.map((x) =>
                                                <option value={x.categoryName}>{x.categoryName}</option>
                                            )}
                                    </select>
                                </div> */}
                                {/* <div className="form-group col-md-12 mt-3">
                                    <label For="resName">Select Cuisine</label>
                                    <select name="department" class="form-control widthValidate selectpicker" onChange={(e) => setcuisine(e.target.value)} required>
                                        <option value="Selected Class">Select Cuisine</option>
                                        {state.cuisine?.length > 0 &&
                                            state.cuisine.map((x) =>
                                                <option value={x}>{x}</option>
                                            )}
                                    </select>
                                </div> */}
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-6 col-sm-12  ">
                            <div className="form-row">

                                <div className="form-group col-md-12">
                                    <label For="resAddress">Additional Option Details</label>
                                    <div className="row bg-white">
                                        <div className="col-12"><label For="resAddress" style={{ fontSize: "14px" }}>Quantity</label></div>
                                        <div className="col-4 text-center mt-2" >
                                            <input type="text" className="form-control" placeholder="Quantity in Kg, piece ...etc" value={size} onChange={(e) => setsize(e.target.value)} />
                                        </div>
                                        <div className="col-4 text-center mt-2">
                                            <input type="text" className="form-control" placeholder="Price" value={sizePrice} onChange={(e) => setsizePrice(e.target.value)} />
                                        </div>
                                        <div className="col-4 text-center mt-2">
                                            {size != "" && sizePrice != "" ?
                                                <button type='button' className='btn uploadImage mb-2' onClick={(e) => { addarray(e, "addSize") }}>Add Quantity</button>
                                                :
                                                <button type='button' className='btn uploadImage mb-2' disabled>Add Quantity</button>
                                            }
                                        </div>
                                        {addSize.length > 0 && addSize.map((x) =>
                                            <div className="col-12 mt-2"><div className="container">
                                                <div className="row mt-2 mt-2" style={{ fontSize: "12px" }}>
                                                    <div className="col-4 text-center  text-capitalize">{x.size}</div>
                                                    <div className="col-4 text-center ">{x.sizePrice}</div>
                                                    <div className="col-4 text-center ">
                                                        <button type="button" className='removeButton  btn' onClick={(e) => remove(e, x, "addSize")}><i class="fa fa-trash" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        )}
                                        <div className="col-12 mt-3"><label For="resAddress" style={{ fontSize: "14px" }}>Additional Ingredient</label></div>
                                        <div className="col-4 text-center mt-2" >
                                            <input type="text" className="form-control" placeholder="ingredient like extra sauce etc" value={ingredient} onChange={(e) => setingredient(e.target.value)} />
                                        </div>
                                        <div className="col-4 text-center mt-2">
                                            <input type="text" className="form-control" placeholder="Price" value={ingredientPrice} onChange={(e) => setingredientPrice(e.target.value)} />

                                        </div>
                                        <div className="col-4 text-center mt-2">
                                            {ingredient != "" && ingredientPrice != "" ?
                                                <button type='button' className='btn uploadImage mb-2' onClick={(e) => { addarray(e, "addIngredient") }}>Add Ingredient</button>
                                                : <button type='button' className='btn uploadImage mb-2' disabled>Add Ingredient</button>}
                                        </div>

                                        {addIngredient.length > 0 && addIngredient.map((x) =>
                                            <div className="col-12 mt-2"><div className="container">

                                                <div className="row mt-2" style={{ fontSize: "12px" }}>
                                                    <div className="col-4 text-center  text-capitalize">{x.ingredient}</div>
                                                    <div className="col-4 text-center ">{x.ingredientPrice}</div>
                                                    <div className="col-4 text-center "><button type="button" className='removeButton  btn' onClick={(e) => remove(e, x, "addIngredient")}><i class="fa fa-trash" aria-hidden="true"></i></button>
                                                    </div>


                                                </div>
                                            </div>
                                            </div>
                                        )}

                                    </div>
                                </div>


                            </div>
                         
                        </div> */}

                        <div className="col-md-12">
                            <div className="text-center">
                            <button className='btn btnsubmit'  >submit</button>
                                </div>

                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddFood;