import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import NavBar from '../Navbar/navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import '../Restaurant/restaurant.css'
import '../getpackage/getPackage.css'
const Foodlist = () => {
    const { state } = useLocation();
    const token = localStorage.getItem('admintoken')
    const [item, setItems] = useState([]);
    const navigate = useNavigate()
    async function getallFoodList() {
        const data = await axios({
            'method': 'GET',
            'url': `https://node.firehrm.com/FB/flame/restaurant/getFoodByOwner`,
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
        console.log(data.data.data,"24")
        // const data = await res.json()
        setItems(data.data.data);
    };


    async function Delete(e, id) {
        e.preventDefault()
        console.log(id);
        if (window.confirm("Are you sure to Delete")) {
            await fetch('https://node.firehrm.com/FB/flame/restaurant/deleteMenu/' + id, {
                method: 'DELETE',
                header: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (response.status == 200 || response.status == 204) {
                        toast.success('Deleted Successfull', {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        }
                        );
                        getallFoodList()
                    }
                })
                .then(data => {
                    console.log(data)
                }).catch(error => {
                    toast.error('Some Problem Occur. Try Again or later', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                })
        }
    }
    function Edit(e, id) {
        // let object = { list, state }
        navigate('/updateFood', { state: id })
    }

    function nav(e, x) {
        navigate('/addFood', { state: state })
    }
    useEffect(() => {
        getallFoodList()
    }, []);
    
    return (<>  <NavBar />
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <div className="content-wrapper">
            <div className="container mt-5">
                <div className="text-center row">
                    <div className="col-2 mb-5">
                        <button type="button" className=' ms-3 text-light btnaddnew btn fw-bold' onClick={(e) => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i></button>

                    </div>
                    <div className="col-lg-8">
                        <h5 className="text-center mt-1 mb-5 loginAcc">Add New Food</h5>
                    </div>
                    <div className="col-lg-2">
                        <p> <button type="button" className=' ms-3 text-light btnaddnew btn fw-bold mt-2' onClick={(e) => nav(e)}>+</button> </p>
                    </div>
                </div>

                {item.length > 0 ?
                    <div className="card">
                        <div className="row mt-2 text-center">
                            <div className="col-2 mt-4">
                                <p className='bodyRes fw-bold'>Food Image</p>
                            </div>
                            <div className="col-2 mt-4">
                                <p className='bodyRes fw-bold'>Food Name</p>
                            </div>
                            <div className="col-2 mt-4">
                                <p className='bodyRes fw-bold'>Food Category</p>
                            </div>
                            <div className="col-2 mt-4">
                                <p className='bodyRes fw-bold'>Food Cuisine</p>
                            </div>
                            <div className="col-2 mt-4">
                                <p className='bodyRes fw-bold'>Food Price</p>
                            </div>
                            <div className="col-2">

                            </div>
                        </div>
                    </div> : ""
                }

                <div className="text-center">
                    {item.map((y =>
                        <>
                            <div className="card">
                                <div className="row my-2 ">
                                    <div className="col-2">
                                        <img
                                            src={y.foodImage}
                                            className="image1 img-fluid"
                                            alt="Thumb"
                                        />
                                    </div>
                                    <div className="col-2 mt-4">
                                        <p className='bodyRes fw-bold'>{y.foodName}</p>
                                    </div>
                                    <div className="col-2 mt-4">
                                        <p className='bodyRes '>{y.category}</p>

                                    </div>
                                    <div className="col-2 mt-4">
                                        <p className='bodyRes '>{y.cuisine}</p>
                                    </div>
                                    <div className="col-2 mt-4">
                                        <p className='bodyRes '>{y.foodPrice}</p>
                                    </div>
                                    <div className="col-2">
                                        <button type="button" className='btn me-1' onClick={(e) => Edit(e, y)}><i class="fa fa-edit"></i></button><button type="button" className='btn ' onClick={(e) => Delete(e, y._id)}><i class="fa fa-trash" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}</div>
                {item.length == 0 &&
                    <div className="text-center mt-3">
                        <p>No result Found. Add Package</p>
                    </div>
                }
            </div>
        </div>
    </>);

}



export default Foodlist;