import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// import useGeolocation from "react-hook-geolocation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import LockIcon from '@mui/icons-material/Lock';
import { AuthChild } from '../auth';
import './login.css';
export default function LoginSuper() {
  //   const geolocation = useGeolocation();
  const navigate = useNavigate()
  const [password, setPassword] = useState("");
  const [email, setUserName] = useState("");
  AuthChild.isAuthenticated()
  console.log(AuthChild.isLogged)
  const login = async (e) => {
    e.preventDefault();
    let payload = { password, email }
    console.log(payload)
    let result1 = await fetch('https://node.firehrm.com/FB/flame/superAdmin/superAdminLogin',
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/JSON'
        },
        body: JSON.stringify(payload)
      });
    console.log(result1.status)
    let result = await result1.json();
    let token = result.token;
    console.log(token)

    if (token != undefined || token != null) {
      localStorage.setItem("superadmintoken", result.token);
      // localStorage.setItem("Id",id);
    }
    console.log(result1.status)
    if (result1.status == 200 || result1.status == 201) {
      toast.success('Login Sucuessfull', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate('/')
      window.location.reload()
    }
    else if (result1.status == 400) {
      toast.warn('Invalid Input', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else {
      toast.error('Something went wrong', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div class="login">
        <div class="form">
          <form class="login-form">
            <div className='centerhead'>
              <span class="material-icons head">lock</span>
              <span className='head'>Super Admin</span></div>
            {/* <LockIcon/>s */}
            <input type="email" placeholder="email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" value={email} onChange={(e) => { setUserName(e.target.value) }} required />
            <input type="password" placeholder="password" required value={password} onChange={(e) => { setPassword(e.target.value) }} />
            <Link to='/loginAdmin' className='adminLink'>Login As Restaurant Admin</Link>
            <button onClick={login}>login</button>
          </form>
        </div>
      </div>


    </>
  )
}