import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import NavBar from '../Navbar/navbar';
import './restaurant.css'
import '../getpackage/getPackage.css'
const RestaurantList = ({ ResAdminData }) => {
    const navigate = useNavigate();
    const limit = 10;
    const token = localStorage.getItem('admintoken')
    const [resList, setResList] = useState([]);

    useEffect(() => {
        GetRes();
    }, []);
    const GetRes = async () => {
        axios({
            'method': 'GET',
            'url': 'https://node.firehrm.com/FB/flame/restaurant/getSpecificRestaurant',
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
            .then((response) => {
               console.log(response,"29")
                setResList(response.data.data)
            })
            .then
            .catch((error) => {
                alert(error)
            }
            )
    }

    function navtoAddAdmin(e) {
        e.preventDefault()
        navigate('/addRes')
    }
    async function Delete(e, id) {
        e.preventDefault()
        console.log(id);
        if (window.confirm("Are you sure to Delete")) {
            await fetch('https://node.firehrm.com/FB/flame/restaurant/removeRestaurant/' + id, {
                method: 'DELETE',
                header: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (response.status == 200 || response.status == 204) {
                        toast.success('Deleted Successfull', {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                        window.location.reload(false)
                    }
                })
                .then(data => {

                    console.log(data)
                }).catch(error => {
                    toast.error('Some Problem Occur. Try Again or later', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                })
        }
    }
    async function Edit(e, id) {
        console.log("Edit")
        navigate('/updateRes', { state: id })
    }
    async function Add(e, id) {
        navigate('/addFood', { state: id })
    }
    function navsingle(e, list) {

        navigate('/viewSingleRes', { state: list })
    }
    return (<>
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
        <NavBar />

        <div className="content-wrapper">
            <div className="container mt-5">
                <div className="row">

                    <div className="col-7"></div>
                    <div className="col-2 mt-3">
                        {/* {ResAdminData.addAccess < Count ? */}
                        <button type="button" className=' ms-3 text-light btnaddnew btn fw-bold' onClick={(e) => navtoAddAdmin(e)}>+</button>
                        {/* :
                            <button type="button" className=' ms-3 text-light btnaddnewdisable btn fw-bold btn-secondary' disabled>+</button>
                        } */}

                    </div>
                </div>

                <table className='mt-5'>
                    <thead>
                        <tr>

                            <th>Name</th>
                            <th>Email</th>
                            <th>Contact</th>
                            {/* <th>Address</th> */}
                            {/* <th>Details</th> */}
                            <th>Action</th>
                        </tr>
                    </thead>

                    {resList?.map(list =>
                        <tr>
                            <td>{list.restaurantName}</td>
                            <td>{list.restaurantEmail}</td>
                            <td>{list.restaurantPhoneNo}</td>
                            {/* <td>{list.restaurantAddress}</td> */}
                            {/* <td><button type="button" className='btn me-1 btn-primary buttonSizeAcceptReject' onClick={(e) => navsingle(e, list)}>View</button></td> */}
                            <td><button type="button" className='btn me-1' onClick={(e) => Edit(e, list)}><i class="fa fa-edit"></i></button><button type="button" className='btn ' onClick={(e) => Delete(e, list._id)}><i class="fa fa-trash" aria-hidden="true"></i></button></td>
                            <td><button type="button" className='btn me-1' onClick={(e) => Add(e, list)}>Add Food</button></td>

                        </tr>
                    )}

                </table>
                {/* <div className='mt-5'>
                    {item?.length > 0 &&
                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            // onPageChange={handlePageClick}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                        />}</div> */}
            </div>
        </div>
    </>);
}



export default RestaurantList;

