import './restaurant.css';
import React, { useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import NavBar from '../Navbar/navbar';
const UpdateRes = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    console.log(state);
    const myArray = state.address.split(",");
    const [restaurantEmail, setEmail] = useState(state.restaurantEmail);
    const [restaurantName, setName] = useState(state.restaurantName);
    const [restaurantPhoneNo, setPhone] = useState(state.restaurantPhoneNo);
    const [aboutMenu, setaboutMenu] = useState(state.aboutMenu);
    // const [restaurantAddress, setAddress] = useState(state.restaurantAddress);
    const [averagePrice, setAveragePrice] = useState(state.averagePrice);
    const [cuisine, setCuisine] = useState(state.cuisine);
    const [cuisineName, setCuisineNmae] = useState("");
    const [restaurantImage, setRestaurantImage] = useState(state.restaurantImage);
    const [information, setInformation] = useState(state.information);
    const [city, setcity] = useState(myArray[0]);
    const [area, setArea] = useState(myArray[1]);
    const [country, setCountry] = useState(myArray[2]);
    const [openingTime, setOpeningTime] = useState(state.restaurantTime.openingTime);
    const [closingTime, setClosingTime] = useState(state.restaurantTime.closingTime);
    const [categoryName, setCategoryName] = useState("")
    const [category, setCategory] = useState(state.category)
    const [categoryDetails, setCategoryDetails] = useState("")
    const [offer, setOffer] = useState(state.offer)
    const [leaveDay, setLeaveDay] = useState(state.leaveDay);
    const [service, setService] = useState(state.service);
    const[image,setImage]=useState([]);
    const updateRes = async (e) => {
        e.preventDefault();
        let restaurantTime = { openingTime, closingTime }
        let address = area + "," + city + "," + country
        let res = { information, restaurantImage, averagePrice,aboutMenu, category, restaurantName, restaurantEmail, address, restaurantPhoneNo , cuisine, restaurantTime, offer, leaveDay, service }
        console.log(res)
        const token = localStorage.getItem('usertoken');
       
        console.log(token);
        await fetch('https://node.firehrm.com/FB/flame/restaurant/updateRestaurant/' + state._id, {
            method: 'PUT',
            headers: {
                'Content-type': "application/json"
            },
            body: JSON.stringify(res)
        }).then(data => {
            console.log(data)
            let status = data.status;
            if (status == 200 || status == 201) {
                toast.success('Updated Successfull', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                setEmail('');setaboutMenu("")
                setName(''); setPhone('');  setcity(''); setArea(''); setCountry(''); setAveragePrice(""); setCuisine([]); setCuisineNmae([]); setRestaurantImage("");
              setInformation(""); setOpeningTime(""); setClosingTime(""); setCategoryName(""); setCategory([]); setCategoryDetails("");
                setOffer(""); setLeaveDay(""); setService(""); 
                navigate('/getRes')
            } else if (status == 400) {
                toast.warn('Input Invaild', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
            else if (status == 500) {
                toast.error('SomeThing Went Wrong', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
            else {
                toast.error('SomeThing Went Wrong', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }

        }).catch(error => {
            toast.error('SomeThing Went Wrong', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        })
    }
   
    function remove(e, list, type) {
        switch (type) {
          
            case "category":
                let value1 = category.filter((x) => x != list)
                setCategory(value1)
                break;
            case "cuisine":
                let value2 = cuisine.filter((x) => x != list)
                setCuisine(value2)
                break;
            default:
                break;
        }

    }
    function addarray(e, type) {
        e.preventDefault()
        switch (type) {
            case 'category':
                let object = { categoryName, categoryDetails }
                setCategory([...category, object])
                setCategoryDetails(""); setCategoryName("")
                break;
            case 'cuisine':

                setCuisine([...cuisine, cuisineName])
                setCuisineNmae("")
                break;
            default:
                break;
        }
    }
    function addcategory(e) {
        let object = { categoryName, categoryDetails }
        setCategory([...category, object])
        setCategoryDetails(""); setCategoryName("")

    }
    async function urlConvertor(e) {
        e.preventDefault();
        const formData1 = new FormData()
        formData1.append('image', image);
        let response = await fetch('https://node.firehrm.com/FB/flame/restaurant/image', {
            method: 'POST',
            body: formData1
        })
        response = await response.json()
        // console.log(response.message)
        setRestaurantImage(response.message.image)

    }
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            <NavBar />
            <div class="content-wrapper">
                <form onSubmit={updateRes}>
                    <div className="row mx-3 mt-2">
                        <div className="col-lg-12 mb-4">
                            <h5 className="text-center mb-4 loginAcc">Register New Restaurant</h5>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 ">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label For="resName">Restaurant Name</label>
                                    <input type="text" className="form-control" id="resName" placeholder="Enter Restaraunt Name" value={restaurantName} onChange={(e) => setName(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-12 mt-2">
                                    <label For="resEmail">Restaurant Email</label>
                                    <input type="email" className="form-control" id="resEmail" placeholder="Enter Email Address" value={restaurantEmail} onChange={(e) => setEmail(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-12">
                                    <label For="resContact">Mobile Number</label>
                                    <input type="number" className="form-control" placeholder="Mobile Number" value={restaurantPhoneNo} onChange={(e) => setPhone(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-12">
                                    <label For="resAddress">Address</label>
                                </div>
                                {/* <div className="form-group col-md-12">

                                    <input type="text" className="form-control" placeholder="Full Address" value={restaurantAddress} onChange={(e) => setAddress(e.target.value)} required />
                                </div> */}
                                <div className="form-group col-md-4">
                                    <input type="text" className="form-control" placeholder="Area" value={area} onChange={(e) => setArea(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-4">
                                    <input type="text" className="form-control" placeholder="City" value={city} onChange={(e) => setcity(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-4">
                                    <input type="text" className="form-control" placeholder="Country" value={country} onChange={(e) => setCountry(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-12">
                                    <label For="resAddress">Category</label>
                                    <div className="row bg-white">
                                        <div className="col-4 mt-2" >
                                            <label For="resAddress" className=" categorydetail">Category Name</label>
                                        </div>
                                        <div className="col-8 mt-2">
                                            <input type="text" className="form-control" placeholder="Category like Starter,Main Course ...etc" value={categoryName} onChange={(e) => setCategoryName(e.target.value)} />

                                        </div>
                                        <div className="col-4 mt-2">
                                            <label For="resAddress" className=" categorydetail">About your Category</label>
                                        </div>
                                        <div className="col-4 mt-2 mb-2">
                                            <input type="text" className="form-control" placeholder="About above category" value={categoryDetails} onChange={(e) => setCategoryDetails(e.target.value)} />

                                        </div>
                                        <div className="col-12">
                                            <button type='button' className='btn uploadImage mb-2' onClick={(e) => { addcategory(e) }}>Add Category</button>
                                        </div>
                                        {category.length > 0 && category.map((x) =>
                                            <div className="col-12 mt-2">
                                                <div className="card text-center">
                                                    <h6 className='text-capitalize'>{x.categoryName}</h6>
                                                    <p className='text-capitalize'>{x.categoryDetails}</p>
                                                    <button type="button" className='removeButton form-control btn' onClick={(e) => remove(e, x, "category")}>Remove</button>

                                                </div>

                                            </div>
                                        )}

                                    </div>
                                </div>
                                <div className="form-group col-md-12 mt-3">
                                    <label For="resName">Average Price</label>
                                    <input type="text" className="form-control" id="resName" placeholder="Average Price for your Foods" value={averagePrice} onChange={(e) => setAveragePrice(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-12">
                                    <label For="resAddress">Cuisine</label>
                                    <div className="row bg-white">

                                        <div className="col-8 mt-2">
                                            <input type="text" className="form-control" placeholder="Cuisine like Indian,Mexican,Veg,Pizza .... etc" value={cuisineName} onChange={(e) => setCuisineNmae(e.target.value)} />

                                        </div>
                                        <div className="col-4 mt-2">
                                            <button type='button' className='btn uploadImage mb-2' onClick={(e) => { addarray(e, "cuisine") }}>Add Cuisine</button>
                                        </div>
                                        {cuisine?.length > 0 && cuisine?.map((x) =>
                                            <div className="col-3">
                                                <div className="card">
                                                    <p className='text-center mt-1text-capitalize'>{x}</p>
                                                    <button type="button" className='removeButton form-control btn' onClick={(e) => remove(e, x, "cuisine")}>Remove</button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group col-md-12 mt-2">
                                    <label For="resEmail">Offers</label>
                                    <input type="text" className="form-control" id="resEmail" placeholder="Your Offers" value={offer} onChange={(e) => setOffer(e.target.value)} required />
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12  ">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label For="res1">Restaurant Profile Image</label>
                                    <div className=''>
                                        {restaurantImage &&
                                            <img
                                                src={restaurantImage}
                                                className="image1 img-fluid"
                                                alt="Thumb"
                                            />}
                                    </div>
                                    <div className="row">
                                        <div className="col-8">
                                            <label For="resImage" className='labelImage'>Click me </label>
                                            <input
                                                accept="image/*"
                                                type="file"
                                                onChange={(e) => setImage(e.target.files[0])}
                                                id='resImage'

                                                style={{ visibility: "hidden" }}
                                            // className='uploadImage'
                                            />
                                        </div>
                                        <div className="col-4">
                                            <button type="button" For="resImage" className='btn uploadImage' onClick={(e) => { urlConvertor(e, "restaurantImage") }}>Upload</button>

                                        </div>
                                    </div>
                                </div>
                               

                                <div className="form-group col-md-12 mt-3">
                                    <label For="resName">About your Restaurant</label>
                                    <textarea cols="50" rows="4" className="form-control" placeholder="" value={information} onChange={(e) => setInformation(e.target.value)} required></textarea>
                                    {/* <input type="text" className="form-control" id="resName" placeholder="Average Price for your Foods" value={averagePrice} onChange={(e) => setAveragePrice(e.target.value)} required /> */}
                                </div>
                                <div className="form-group col-md-12 mt-3">
                                    <label For="resName">About your Menu & Food Details</label>
                                    <textarea cols="50" rows="4" className="form-control" placeholder="" value={aboutMenu} onChange={(e) => setaboutMenu(e.target.value)} required></textarea>
                                    {/* <input type="text" className="form-control" id="resName" placeholder="Average Price for your Foods" value={averagePrice} onChange={(e) => setAveragePrice(e.target.value)} required /> */}
                                </div>
                                <div className="form-group col-md-6 mt-2">
                                    <label For="resEmail">Opening Time</label>
                                    <input type="text" className="form-control" id="resEmail" placeholder="Enter Opening Time" value={openingTime} onChange={(e) => setOpeningTime(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-6 mt-2">
                                    <label For="resEmail">Closing Time</label>
                                    <input type="text" className="form-control" id="resEmail" placeholder="Enter Closing Time" value={closingTime} onChange={(e) => setClosingTime(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-12 mt-3">
                                    <label For="resName">Leave Day</label>
                                    <input type="text" className="form-control" id="resName" placeholder="Leave Day" value={leaveDay} onChange={(e) => setLeaveDay(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-12 mt-2">
                                    <label For="resEmail">Payment Accept Method</label>
                                    <input type="text" className="form-control" id="resEmail" placeholder="Payment like visa card, master card, Paypal ...etc" value={service} onChange={(e) => setService(e.target.value)} required />
                                </div>
                              
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="text-center">
                                <input type="submit" className='btn btnsubmit' value="submit" /></div>
                        </div>

                    </div>
                </form>
            </div>
        </>
    )
        ;
}
export default UpdateRes;