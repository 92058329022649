import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import NavBar from '../Navbar/navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import '../Restaurant/restaurant.css'
import '../getpackage/getPackage.css'
const GetAdminRequest = () => {
    const navigate = useNavigate();
    //Paginate
    const [pageCount, setpageCount] = useState(0);
    const[currentPage,setCurrentPage] = useState(1);
    const limit = 10;

    //store responseData
    const [item, setItems] = useState([]);

// 
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    async function getallrequest(currentPage) {
        const data = await axios({
            'method': 'GET',
            'url':  `https://node.firehrm.com/FB/flame/admin/getAdminRequestList?page=${currentPage}&limit=${limit}`,
            'headers': {
                'Content-Type': 'application/json',
            },
        })
        // console.log(data.data.message,'data')
        setFilteredResults(data.data.message)
        // console.log(currentPage);
        // const data = await res.json()
        const total =  data.data.count;
        // console.log(total)
        setpageCount(Math.ceil(total / limit));
        let result = data.data.message.result;
        // console.log(result)
        let data1=result.filter((x)=>x.status==0)
        setItems(data1);
    };
    const handlePageClick = async (data) => {
        console.log(data.selected);
       setCurrentPage(data.selected + 1);
         getallrequest(currentPage);
    };
    useEffect(() => {
        getallrequest(currentPage)
    }, [limit,currentPage]);
    const searchItems = async (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = item.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            // setFilteredResults(filteredData)
        }
        else {
            // setFilteredResults(item)
        }
    }
    function navtoAdd(e,list) {
        navigate('/getviewrequest',{state:list})
    }
    async function Reject(e, list) {
        e.preventDefault();
        let payload={role:"reject"}
        const data = await fetch(`https://node.firehrm.com/FB/flame/admin/acceptAdmin/`+list._id,{
            'method': 'POST',
            'headers': {
                'Content-Type': 'application/json',
           
            },
            'body':JSON.stringify(payload)
        }).then(res=>{if(res.status==200||res.status==201)
            
        {  window.location.reload(false)
            toast.success(' Reject '+list.restaurantName+ "Sucuessfull", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });	
        }})}
    async function Accept(e,list) {
        e.preventDefault();
        let payload={role:"accept"}
        const data = await  fetch(`https://node.firehrm.com/FB/flame/admin/acceptAdmin/`+list._id,{
            'method': 'POST',
            'headers': {
                'Content-Type': 'application/json',
            },    'body':JSON.stringify(payload)

        }).then(res=>{if(res.status==200||res.status==201)
            {
                getallrequest(currentPage)
                toast.success(' Add '+list.restaurantName+ "Sucuessfull", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });	
            }})
    }
    return (<>  <NavBar/>
   <ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
    <div className="content-wrapper">
        <div className="container mt-5">
            <div className="text-center">
                <h3 className=''>Restaurant Request</h3>
            </div>
            {/* <p>You can Add only Three Packages</p> */}
            {/* <div className="row">
                <div className="col-lg-4 col-md-7 col-sm-7">
                    <div className="input-group mt-3 mb-3">
                        <input type="text" className="textfield form-control" placeholder="Search Restaurant" onChange={(e) => searchItems(e.target.value)} />
                        <button className="btn text-light btnstyle" type="button" ><i className="fa fa-search" aria-hidden="true"></i></button>
                    </div></div>
                <div className="col-lg-6 col-md-1"></div>
               
            </div> */}

            <table className='mt-5'>
                {item.length==0?"":
                <thead>
                    <tr>
                        {/* <th scope="col">S.No</th> */}
                        <th scope="col">Name</th>
                        {/* <th scope="col">Email</th> */}
                        <th scope="col">Restaurant Name</th>
                        <th scope="col">Address</th>
                        <th scope="col">City</th>
                        <th scope="col">Country</th>
                        <th scope="col">Details</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>}

                { filteredResults.map((list =>
                        <tr>{list.status==0 &&
                    <>
                            <td data-label="Name">{list.name}</td>
                            <td data-label="Restaurant Name">{list.restaurantName}</td>
                            <td data-label="Address">{list.restaurantAddress}</td>
                            <td data-label="City">{list.restaurantCity}</td>
                            <td data-label="Country">{list.restaurantCountry}</td>
                            <td data-label="View"><button type="button" className='btn me-1 btn-primary buttonSizeAcceptReject' onClick={(e)=>navtoAdd(e,list)}>View All</button></td>
                        <td data-label="Action"><button type="button" className='btn me-1 btn-success buttonSizeAcceptReject' onClick={(e)=>Accept(e,list)}>Accept</button><button type="button" className='btn btn-danger buttonSizeAcceptReject' onClick={(e) => Reject(e, list)}>Reject</button></td>
                          </>
                        } 
                        </tr>
                    )) 
                }
            </table>
           {filteredResults.length==0 &&
           <div className="text-center mt-3">
               <p>No result Found. Add Package</p>
           </div> 
           }
          <div className='mt-5'>
                <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                />
                </div>
        </div></div>
    </>);
}

export default GetAdminRequest;

