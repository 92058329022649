import React from 'react';
import axios from "axios";
import { useNavigate, useLocation } from 'react-router-dom';
const Getviewrequest = () => {
    const { state } = useLocation();

    return (
        <>
            <div className="content-wrapper">
                <div className="container">
                    <div className="row mt-5">
                        {/* <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="card mx-3">
                                <div className="">
                                    <h4 className=' text-center mt-2'>Bank Details</h4>
                                    <hr />
                                    <p className='ms-2 me-1'><strong>Bank Name  : </strong>{state.bankDetails.bankName}</p>
                                    <p className='ms-2 me-1'><strong>Account Number  : </strong>{state.bankDetails.accountNumber}</p>
                                    <p className='ms-2 me-1'><strong>IFSC Code  : </strong>{state.bankDetails.ifscCode}</p>
                                    <p className='ms-2 me-1'><strong>PAN Number : </strong>{state.bankDetails.panNumber}</p>

                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-8 col-md-6 col-sm-12">
                            <div className="card">
                            <h4 className=' text-center mt-2'>Restaurant Details</h4>
                                    <hr />
                                <p className='ms-2 me-1'><strong>Restaurant Name  : </strong><span className='text-right'>{state.restaurantName}</span></p>
                                <p className='ms-2 me-1'><strong>Restaurant Address  : </strong>{state.restaurantAddress}</p>
                                <p className='ms-2 me-1'><strong>Restaurant City  : </strong>{state.restaurantCity}</p>
                                <p className='ms-2 me-1'><strong>Restaurant Country : </strong>{state.restaurantCountry}</p>
                                <p className='ms-2 me-1'><strong>User Name : </strong>{state.userName}</p>
                                <p className='ms-2 me-1'><strong>Email : </strong>{state.email}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Getviewrequest;