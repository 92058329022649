import React, { useState } from 'react';
import './content.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';

function AddContent() {
    const navigate = useNavigate()
    const { state } = useLocation()
    console.log(state, "state")
    const [title, setTitle] = useState(state != null ? state.title : "")
    const [content, setContent] = useState(state != null ? state.content : "");
    async function CreateContent(e) {
        e.preventDefault();
        let payload = { title,content }
        console.log(payload)
        if (state == null) {
            var url = "https://node.firehrm.com/FB/flame/management/createContent"
            var method = "POST"
        }
        else {
            var url = "https://node.firehrm.com/FB/flame/management/updateContent/" + state._id;
            var method = "PUT"
        }
        await fetch(url,
            {
                method: method,
                headers: {
                    'Content-type': 'application/JSON'
                },
                body: JSON.stringify(payload)
            }).then(res => {
                console.log(res)
                if (res.status == 200 || res.status == 201) {

                    toast.success(' Add Package Successfull ', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    navigate('/getcontent')
                }
                else if (res.status == 400) {

                    toast.warn('Invalid Input', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                }
                else if (res.status == 500) {

                    toast.error('Internal Error. Try again', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                else {

                    toast.error('Something went wrong. Try again', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }).catch(err => {
                console.log(err)
                alert("Something went wrong. Try again")
                toast.error('Something went wrong. Try again', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
    }
    return (
        <div class="content-wrapper">
            <p className='text-center fw-bold fs-5 mt-5'>Package</p>
            <form onSubmit={(e) => CreateContent(e)}>
                <div className="form-row mx-5 ">
                    <div className="form-group col-md-6 mt-2">
                        <label For="resName">Title</label>
                        <input type="text" className="form-control" id="resName" placeholder="Package Name" value={title} onChange={(e) => setTitle(e.target.value)} required />
                    </div>
                    <div className="form-group col-md-12 mt-2">
                        <label For="resContact">Content</label>
                        <textarea name="" id="" cols="10" rows="5" className="form-control" placeholder="Package Description" value={content} onChange={(e) => setContent(e.target.value)} required></textarea>
                    </div>
                    <div className="form-group col-md-12 mt-2">
                        <div className="text-center">
                            <input type="submit" value="Submit" className='submit btn' />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddContent