import logo from '../../assets/img/Flame Bee8.png'
import Helmet from 'react-helmet';
import React, { useState, useEffect } from 'react';
import axios from "axios";
const SuperAdminHome = () => {

    const [item, setItems] = useState([]);
    const [RevenueToday, setRevenueToday] = useState([]);
    const [TotalOrder, setTotalOrder] = useState([]);
    const [TodayOrder, setTodayOrder] = useState([]);

    async function TotalRevenue() {
        const data = await axios({
            'method': 'GET',
            'url': `https://node.firehrm.com/FB/flame/order/getSuperAdminTotalRevenue`,
            'headers': {
                'Content-Type': 'application/json',
            },
        })
        // console.log(data.data.message, 'reve')
        let result = await data.data.message[0];
        setItems(result)
    };
   
    async function TodayRevenue() {
        const data = await axios({
            'method': 'GET',
            'url': `https://node.firehrm.com/FB/flame/order/getSuperAdminTodayRevenue`,
            'headers': {
                'Content-Type': 'application/json',
            },
        })
        // console.log(data.data.message[0], 'reve')
        let result = await data.data.message[0];
        setRevenueToday(result)
    };
  
    async function TotalOrders() {
        const data = await axios({
            'method': 'GET',
            'url': `https://node.firehrm.com/FB/flame/order/getTotalOrderCount`,
            'headers': {
                'Content-Type': 'application/json',
            },
        })
        // console.log(data.data, 'tr')
        let result = await data.data;
        setTotalOrder(result)
    };
   
    async function TodayOrders() {
        const data = await axios({
            'method': 'GET',
            'url': `https://node.firehrm.com/FB/flame/order/getTodayOrderCount`,
            'headers': {
                'Content-Type': 'application/json',
            },
        })
        console.log(data.data.message, 'to')
        let result = await data.data.message;
        setTodayOrder(result)
    };
    useEffect(() => {
        TotalRevenue();
        TotalOrders();
        TodayOrders();
        TodayRevenue();
    }, []);

    return (
        <>  
        <div class="content-wrapper">
            <div class="container-fluid">
                {/* <!-- Breadcrumbs--> */}
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a href="#">Dashboard</a>
                    </li>
                    <li class="breadcrumb-item active">My Dashboard</li>
                </ol>
                {/* <!-- Icon Cards--> */}
                <div class="row">
                    <div class="col-xl-3 col-sm-6 mb-3">
                        <div class="card dashboard text-white bg-primary o-hidden h-100">
                            <div class="card-body">
                                <div class="card-body-icon">
                                    <i class="fa fa-fw fa-envelope-open"></i>
                                </div>
                                <div class="mr-5">
                                    <h5>Total Orders!</h5>
                                </div>
                            </div>
                            <a class="card-footer text-white clearfix small z-1" >
                                <span class="float-left">{TotalOrder.message}</span>
                            </a>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 mb-3">
                        <div class="card dashboard text-white bg-warning o-hidden h-100">
                            <div class="card-body">
                                <div class="card-body-icon">
                                    <i class="fa fa-fw fa-star"></i>
                                </div>
                                <div class="mr-5">
                                    <h5>Total Revenue</h5>
                                </div>
                            </div>
                            <a class="card-footer text-white clearfix small z-1" >
                                <span class="float-left">{item?.TotalRevenue}</span>

                            </a>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 mb-3">
                        <div class="card dashboard text-white bg-success o-hidden h-100">
                            <div class="card-body">
                                <div class="card-body-icon">
                                    <i class="fa fa-fw fa-calendar-check-o"></i>
                                </div>
                                <div class="mr-5">
                                    <h5> Today Revenue</h5>
                                </div>
                            </div>
                            <a class="card-footer text-white clearfix small z-1" >
                                <span class="float-left"> {RevenueToday.TodayRevenue}</span>
                            </a>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 mb-3">
                        <div class="card dashboard text-white bg-danger o-hidden h-100">
                            <div class="card-body">
                                <div class="card-body-icon">
                                    <i class="fa fa-fw fa-heart"></i>
                                </div>
                                <div class="mr-5">
                                    <h5>Today Orders!</h5>
                                </div>
                            </div>
                            <a class="card-footer text-white clearfix small z-1">
                                <span class="float-left">{TodayOrder}</span>

                            </a>
                        </div>
                    </div>
                </div>
                {/* <!-- /cards --> */}
                <h2></h2>
                <div class="box_general padding_bottom">
                    <div class="header_box version_2">
                        <h2><i class="fa fa-bar-chart"></i>Statistic</h2>
                    </div>
                    <canvas id="myAreaChart" width="100%" height="30" style={{ margin: "45px 0 15px 0" }}></canvas>
                </div>
            </div>
            {/* <!-- /.container-fluid--> */}
        </div>
            <Helmet>
                <script src="vendor/jquery/jquery.min.js"></script>
                <script src="vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
                <script src="vendor/jquery-easing/jquery.easing.min.js"></script>
                <script src="vendor/chart.js/Chart.js"></script>
                <script src="vendor/datatables/jquery.dataTables.js"></script>
                <script src="vendor/datatables/dataTables.bootstrap4.js"></script>
                <script src="vendor/jquery.magnific-popup.min.js"></script>
                <script src="js/admin.js"></script>
                <script src="js/admin-charts.js"></script>
            </Helmet></>
    );
}

export default SuperAdminHome;