import React, { useState, useEffect } from 'react';
import axios from "axios";
import NavBar from '../Navbar/navbar';
import '../Restaurant/restaurant.css';

function AdminList() {
    const [item, setItems] = useState([]);
    async function allpackage() {
        const data = await axios({
            'method': 'GET',
            'url': `https://node.firehrm.com/FB/flame/admin/getAdminList `,
            'headers': {
                'Content-Type': 'application/json',
            },
        })
        console.log(data.data.message,'adminlist')
        let result = await data.data.message;
        setItems(result)
    };
    useEffect(() => {
        allpackage();
    }, []);


    return (
        <>  <NavBar />

            <div className="content-wrapper">
                <div className="container mt-5">
                    <div className="text-center">
                        <h3 className=''>Admin List</h3>
                    </div>


                    <table className='mt-5'>
                        {item.length == 0 ? "" :
                            <thead>
                                <tr>
                                    <th scope="col">UserName</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Restaraunt Name</th>
                                </tr>
                            </thead>}

                        {item.map((list =>
                            <tr>
                                <td >{list.userName}</td>
                                <td >{list.email}</td>
                                <td >{list.restaurantName}</td>
                                {/* {list.cart.map(x =>
                                    <td >{x.foodName}</td>
                                )} */}

                            </tr>
                        ))
                        }
                    </table>
                    {item.length == 0 &&
                        <div className="text-center mt-3">
                            <p>No result Found</p>
                        </div>
                    }

                </div>
            </div>
        </>
    )
}

export default AdminList