import React, { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import NavBar from '../Navbar/navbar';
import '../Restaurant/restaurant.css'
import '../getpackage/getPackage.css'
const UpdateFood = () => {
    const { state } = useLocation();
    console.log(state._id, 'state')
    const navigate = useNavigate()
    const ref = useRef();
    const ref1 = useRef();
    const [foodName, setFood] = useState(state.foodName);
    const [foodImage, setFoodImage] = useState(state.foodImage);
    const [image, setImage] = useState([]);
    const [category, setcategory] = useState(state.category);
    const [cuisine, setcuisine] = useState(state.cuisine)
    const [foodPrice, setprice] = useState(state.foodPrice);

    // console.log(state)
    const addFood = async (e) => {
        e.preventDefault();
        let restaurantId = state._id
        console.log(restaurantId,'res')
        let payload = { foodName, category, cuisine, foodPrice, foodImage }
        console.log(payload);
        await fetch('https://node.firehrm.com/FB/flame/restaurant/updateMenu/' + restaurantId, {
            method: 'PUT',
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(payload)
        })
            .then(response => response)
            .then(data => {
                console.log(data)

                if (data.status === 200 || data.status === 204) {
                    toast.success('Updated Successfully', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                    setFood("");
                    setFoodImage("");
                    setImage([]);
                    setcategory("");
                    setcuisine("");
                    setprice("");

                } else if (data.status == 400) {
                    toast.warn('Invalid Input', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                }
                else {
                    toast.error('Failed to update. Try again Later', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                }
            }).catch(error => {
                toast.success('Failed to update. Try again Later', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            })
    }
    async function urlConvertor(e) {
        e.preventDefault();
        const formData1 = new FormData()
        formData1.append('image', image);
        let response = await fetch('https://node.firehrm.com/FB/flame/restaurant/image', {
            method: 'POST',
            body: formData1
        })
        response = await response.json()
        console.log(response.data.image)
        // setFoodImage(response.message.image)

    }
    //  function remove(e, list, type) {
    //     e.preventDefault()
    //     switch (type) {
    //         case "addSize":
    //             let value1 = addSize.filter((x) => x != list)
    //             setaddSize(value1)
    //             break;
    //         case "addIngredient":
    //             console.log()
    //             let value2 = addIngredient.filter((x) => x != list)
    //             setAddIngredient(value2)
    //             break;
    //         default:
    //             break;
    //     }

    // }
    // function addarray(e, type) {
    //     e.preventDefault()
    //     switch (type) {
    //         case 'addSize':
    //             console.log('addSize')

    //             setaddSize([...addSize, { size, sizePrice }])
    //             setsize(""); setsizePrice("")
    //             break;
    //         case 'addIngredient':
    //             console.log('addIngredient')

    //             setAddIngredient([...addIngredient, { ingredient, ingredientPrice }])
    //             setingredient(""); setingredientPrice("")
    //             break;

    //         default:
    //             break;
    //     }


    // }
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <NavBar />

            <div class="content-wrapper">
                <form onSubmit={addFood}>
                    <div className="row mx-3 mt-2">
                        <div className="col-2 mb-5">
                            <button type="button" className=' ms-3 text-light btnaddnew btn fw-bold' onClick={(e) => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i></button>

                        </div>
                        <div className="col-lg-8">
                            <h5 className="text-center mt-1 mb-5 loginAcc">Update Food</h5>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12  ">

                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label For="resName">Food Name</label>
                                    <input type="text" className="form-control" id="resName" placeholder="Enter Food Name" value={foodName} onChange={(e) => setFood(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-12">


                                    <label For="res1">Food Image</label>
                                    <div className=''>
                                        {foodImage &&
                                            <img
                                                src={foodImage}
                                                className="image1 img-fluid"
                                                alt="Thumb"
                                            />}
                                    </div>

                                    <div className="row">
                                        <div className="col-8">
                                            <label For="image" className='labelImage'>Click me {image.name != undefined && image.name != null && " : " + image.name}</label>
                                            <input
                                                accept="image/*"
                                                type="file"
                                                onChange={(e) => setImage(e.target.files[0])}
                                                ref={ref}
                                                id='image'

                                                style={{ visibility: "hidden" }}
                                            />
                                        </div>
                                        <div className="col-4">
                                            <button type="button" For="image" className='btn uploadImage' onClick={(e) => { urlConvertor(e, "foodImage") }} required>Upload</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <label For="resAddress">Price</label>
                                    <input type="text" className="form-control" placeholder="Price" value={foodPrice} onChange={(e) => setprice(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-12">
                                    <label For="resAddress">Category</label>
                                    <input type="text" className="form-control" placeholder="Category" value={category} onChange={(e) => setcategory(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-12">
                                    <label For="resAddress">Cuisine</label>
                                    <input type="text" className="form-control" placeholder="Cuisine" value={cuisine} onChange={(e) => setcuisine(e.target.value)} required />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="text-center">
                                <input type="submit" className='btn btnsubmit' value="submit" /></div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default UpdateFood;