import React, { useState } from 'react'
import { Link ,useNavigate} from 'react-router-dom'
// import useGeolocation from "react-hook-geolocation";
import LockIcon from '@mui/icons-material/Lock';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import './login.css'
export default function LoginAdmin() {
  // const geolocation = useGeolocation();
  const navigate=useNavigate()
  const [password,setPassword] = useState("");
  const [userName,setUserName]=useState("");
  const login = async (e) => {
    e.preventDefault();
    let payload = { password, userName}
    console.log(payload)
    let result1=await fetch('https://node.firehrm.com/FB/flame/admin/adminLogin',
        {
            method: 'POST',
            headers: {
                'Content-type': 'application/JSON'
            },
            body: JSON.stringify(payload)
        });
        console.log(result1.status)
       let result = await result1.json();
        let token=result.token;
        console.log(result.token)
        if(token!= undefined||token!=null)
        {
        localStorage.setItem("admintoken",result.token);
        // localStorage.setItem("Id",id);
        }
        console.log(result1.status)
        if(result1.status==200){
          toast.success('Login Sucuessfull', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });	
           navigate('/') 
           window.location.reload(false)
        }
        else if(result1.status==400){
          toast.success('Invalid Input', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });	
        }
        else{
          toast.success('Something WEnt Wrong.Try again ...', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });	
        }
}
  return (
    <>
       <ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
    <div class="login">
  <div class="form" onSubmit={login}>
    <form class="login-form">
    <div className='centerhead'>
      <span class="material-icons head">lock</span>
      <span className='head'>Restaurant Admin</span></div>

      {/* <LockIcon/>s */}
      <input type="text" placeholder="User Name" required onChange={(e)=>{setUserName(e.target.value)}} required/>
      <input type="password" placeholder="password" required  onChange={(e)=>{setPassword(e.target.value)}}/>
      <Link to='/'className='adminLink'>Login As Super Admin</Link>
      <button >login</button>
    </form>  
  </div>
</div>
  

 </>
  )
}