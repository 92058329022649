import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import NavBar from '../Navbar/navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import '../Restaurant/restaurant.css'
import '../getpackage/getPackage.css'
const GetOrderList = () => {
    const { state } = useLocation();
    const token = localStorage.getItem('admintoken')
    const [item, setItems] = useState([]);
    const navigate = useNavigate()
    async function OrderList() {
        const data = await axios({
            'method': 'GET',
            'url': `https://node.firehrm.com/FB/flame/order/getOwnerTotalOrderList`,
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
        // console.log(data.data.message.data, 'order')
        setItems(data.data.message.data);
    };
    useEffect(() => {
        OrderList()
    }, []);
    return (
        <>
            <NavBar />
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="content-wrapper">
                <div className="container mt-5">
                    <div className="text-center row">
                        <div className="col-2 mb-5">
                            <button type="button" className=' ms-3 text-light btnaddnew btn fw-bold' onClick={(e) => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i></button>
                        </div>
                        <div className="col-lg-8">
                            <h5 className="text-center mt-1 mb-5 loginAcc">Order List</h5>
                        </div>

                    </div>
                    <div className="card">
                        <div className="row mt-2 text-center">
                            <div className="col-2 mt-4">
                                <p className='bodyRes fw-bold'>Food Image</p>
                            </div>
                            <div className="col-2 mt-4">
                                <p className='bodyRes fw-bold'>Food Name</p>
                            </div>
                            <div className="col-2 mt-4">
                                <p className='bodyRes fw-bold'>Food Category</p>
                            </div>
                            <div className="col-2 mt-4">
                                <p className='bodyRes fw-bold'>Food Cuisine</p>
                            </div>
                            <div className="col-2 mt-4">
                                <p className='bodyRes fw-bold'>Food Price</p>
                            </div>
                            <div className="col-2">

                            </div>
                        </div>
                    </div>

                    <div className="text-center">
                        {item.map(y =>
                            <>
                                {y.cart.map(x =>
                                    <div className="card">
                                        <div className="row my-2 ">
                                            <div className="col-2">
                                                <img
                                                    src={x.foodImage}
                                                    className="image1 img-fluid"
                                                    alt="Thumb"
                                                />
                                            </div>
                                            <div className="col-2 mt-4">
                                                <p className='bodyRes fw-bold'>{x.foodName}</p>
                                            </div>
                                            <div className="col-2 mt-4">
                                                <p className='bodyRes '>{x.category}</p>

                                            </div>
                                            <div className="col-2 mt-4">
                                                <p className='bodyRes '>{x.cuisine}</p>
                                            </div>
                                            <div className="col-2 mt-4">
                                                <p className='bodyRes '>{x.foodPrice}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}</div>
                    {item.length == 0 &&
                        <div className="text-center mt-3">
                            <p>No Data Found.</p>
                        </div>
                    }
                </div>
            </div>
        </>);

}



export default GetOrderList;