import React from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import axios from "axios";
// import "react-time-picker-input/dist/components/TimeInput.css"
import TimePicker from 'react-time-picker';
import { useState } from 'react';
import NavBar from '../Navbar/navbar';
const ViewSingleRes = () => {
    const {state}=useLocation();
    const [value, setValue] = useState('10:00');
    return(
    <>
    <NavBar/>
    <div className="content-wrapper">
       <div className="container">
        <div>
            <TimePicker
                onChange={(newValue)=>setValue(value)}
                value={value}
            />
        </div>
        </div>
    </div>

    </>
    );
}


export default ViewSingleRes;