import React, { useState, useEffect } from 'react';
import axios from "axios";
import NavBar from '../Navbar/navbar';
import '../Restaurant/restaurant.css';

function RestarauntList() {
    const [item, setItems] = useState([]);
    async function allpackage() {
        const data = await axios({
            'method': 'GET',
            'url': `https://node.firehrm.com/FB/flame/restaurant/getAllRestaurant`,
            'headers': {
                'Content-Type': 'application/json',
            },
        })
        console.log(data.data.data,'user')
        let result = await data.data.data;
        setItems(result)
    };
    useEffect(() => {
        allpackage();
    }, []);

    return (
        <>
          <NavBar />
            <div className="content-wrapper">
                <div className="container mt-5">
                    <div className="text-center">
                        <h3 className=''>Restaraunt List</h3>
                    </div>
                    <table className='mt-5'>
                        {item.length == 0 ? "" :
                            <thead>
                                <tr>
                                    <th scope="col">Restaraunt Name</th>
                                    <th scope="col">Restaraunt Owner Name</th>
                                    <th scope="col">Restaraunt Email</th>
                                </tr>
                            </thead>}

                        {item.map((list =>
                            <tr>
                                <td >{list.restaurantName}</td>
                                <td >{list.restaurantOwner}</td>
                                <td >{list.restaurantEmail}</td>
                

                            </tr>
                        ))
                        }
                    </table>
                    {item.length == 0 &&
                        <div className="text-center mt-3">
                            <p>No result Found</p>
                        </div>
                    }

                </div>
            </div>
        </>
    )
}

export default RestarauntList